import React, { useEffect, useRef, useState } from "react";
import {
  Canvas,
  useThree,
  extend,
  useFrame,
  useLoader,
} from "@react-three/fiber";
import * as THREE from "three";
import { Vector3 } from "three";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { rStats } from "../../helper/rStats";
import { glStats, threeStats } from "../../helper/rStats.extras";
import { gsap } from "gsap";
import { getMediaUrl } from "../../helper/media";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
} from "../../reduxs/explore-modal/action";
import { reqSetActiveTransportOption } from "../../reduxs/home/action";
import { reqSetPage } from "../../reduxs/home/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import _ from "lodash";
import socket from "../../helper/socket";
import App from "./App/App";
import { meshDepthBiasedMaterial } from "./App/meshDepthBiasedMaterial";
import { OrbitCustomControls } from './OrbitCustomControls';
import FPSStats from 'react-fps-stats';

extend({ OrbitCustomControls });

const CanvasBox = React.memo(
  React.forwardRef((props, refScene) => {
    const {
      _3dSetting,
      controls,
      fbxs,
      hotspots,
      isIntroduction,
      objects,
      targetPosition,
      activeObjectIds,
      setActiveObjectIds,
      isPresentation,
    } = props;

    // useEffect(() => {
    //   console.log(hotspots.map((hs) => hs.parent_id));
    // }, [hotspots]);

    const dispatch = useDispatch();

    const light = useRef();
    let glS, tS, rS;
    let timeVector3 = new Vector3(0, 0, 0);
    let alphaVector3 = new Vector3(0, 0, 0);

    let isCameraAnimation = false;

    let position = new THREE.Vector3();

    let selectedHotspotId;
    let selectedHotspot;
    const [isCameraAnimated, setCameraAnimated] = useState(false);
    let hotspot3Ds = [];
    let hotspotHasChildren = {};
    let pointerDownId;
    let hotspotPointerDownId;

    let meshInstanceMap = {};
    const authUser = useSelector((state) => state.user.data);
    let lastPosition = new THREE.Vector3();
    let lastTarget = new THREE.Vector3();
    let pointerDown = false;

    populateMeshInstanceMapKeys(fbxs);
    associateModelsToMap(objects);

    useEffect(() => {
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
          if (content.action === ACTION_NAME.CLICK_HOTSPOT) {
            handleClickHotspot(content.data.hotspot);
          }
        });
      }
    }, [isPresentation]);

    const sendCameraPos = () => {
      if (!isPresentation && controls.current?.didUpdate) {
        lastPosition.x = controls.current.object.position.x;
        lastPosition.y = controls.current.object.position.y;
        lastPosition.z = controls.current.object.position.z;

        lastTarget.x = controls.current.target.x;
        lastTarget.y = controls.current.target.y;
        lastTarget.z = controls.current.target.z;

        let quaternion = {
          x: controls.current.object.quaternion.x,
          y: controls.current.object.quaternion.y,
          z: controls.current.object.quaternion.z,
          w: controls.current.object.quaternion.w,
        };
        socket.emit(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, {
          content: {
            position: lastPosition,
            quaternion: quaternion,
            zoom: controls.current.object.zoom,
          },
          to: authUser?.id,
          from: authUser?.id,
        });
      }
    };

    function getFbxFileName(fbx) {
      return _.snakeCase(fbx.name);
    }

    function getModelFileName(model) {
      return _.snakeCase(model["3d_filename"]);
    }

    function populateMeshInstanceMapKeys(fbxs) {
      fbxs.forEach((fbx) => {
        let entry = { model: fbx, instances: [] };
        let key = getFbxFileName(fbx);
        meshInstanceMap[key] = entry;
      });
    }

    function associateModelsToMap(objects) {
      objects.forEach((obj) => {
        // Make assumption that we can remove .fbx from file_name
        let name = getModelFileName(obj);
        if (!meshInstanceMap[name]) {
          console.warn("No FBX File supplied for", obj);
          return;
        }
        meshInstanceMap[name].instances.push(obj);
      });
    }

    function handleAreaClick(controls, camLookAtPosition, camPosition) {
      return controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
      );
    }

    function updateHotspot() {
      for (let i = 0; i < hotspot3Ds.length; i++) {
        let hotspot3D = hotspot3Ds[i];
        if (!hotspot3D) {
          continue;
        }
        let hotspot = hotspot3D.userData;
        let isVisible = true;
        let isSubHotspot = hotspot.parent_id != null;
        if (isSubHotspot) {
          isVisible = hotspot.parent_id == selectedHotspotId;
        } else if (hotspotHasChildren[hotspot.id]) {
          isVisible = hotspot.id != selectedHotspotId;
        }
        hotspot3D.visible = isVisible;
        if (!isVisible) {
          hotspot3D.layers.set(LAYERS.DISABLE);
        } else {
          hotspot3D.layers.set(hotspot.layer);
        }
      }
    }

    function threePosition(data) {
      return new Vector3(data.x, data.z, -data.y);
    }

    function threePosition2(data, vector) {
      vector.x = data.x;
      vector.y = data.z;
      vector.z = -data.y;
    }

    function setColor(color, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.color.set(color);
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].color.set(color);
            }
          } else {
            child.material.color.set(color);
          }
        }
      });
    }

    function animateAlpha(alpha, object3d) {
      if (alpha == object3d.userData.alpha) {
        return;
      }

      alphaVector3.x = object3d.userData.alpha;
      alphaVector3.y = 0;
      alphaVector3.z = 0;

      gsap.to(alphaVector3, {
        duration: 0.2,
        x: alpha,
        y: 0.0,
        z: 0.0,
        onUpdate: function () {
          setAlpha(alphaVector3.x, object3d);
        },
        onComplete: function () {
          object3d.userData.alpha = alpha;
        },
      });
    }

    function setAlpha(alpha, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.opacity = alpha;
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].opacity = alpha;
            }
          } else {
            child.material.opacity = alpha;
          }
        }
      });
    }

    const handleClickHotspot = async (hotspot) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_HOTSPOT, {
          hotspot,
        });
      }
      switch (hotspot?.link_type) {
        case HOTSPOT_TYPES.FUTURE_ITEM:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT_DETAIL:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.AMENITY:
          dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
          break;
        case HOTSPOT_TYPES.LOCATION_PAGE:
          dispatch(reqSetPage(PAGES.LOCATION_PAGE));
          break;
        case HOTSPOT_TYPES.UNIT_EXPLORE:
          dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
          break;
        case HOTSPOT_TYPES.AMENITY_PAGE:
          dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
        case HOTSPOT_TYPES.MODAL:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
        default:
          if (hotspot.cam_position && hotspot.cam_focus_point_position) {
            // starts an animation with linear interpolation (no slerp)
            controls.current.lookAtAndMovePosition(
              threePosition(hotspot.cam_focus_point_position),
              threePosition(hotspot.cam_position),
            );
          }
          break;
      }

      switch (hotspot.layer) {
        case LAYERS.D_HOTSPOT:
          controls.current.hideLayer(LAYERS.D_HOTSPOT);
          controls.current.showAndEnableLayer(LAYERS.EXPLORE_DISTRICT_HOTSPOT);
        default:
          break;
      }

      if (hotspot.parent_id) {
        return;
      }

      selectedHotspotId = hotspot.id;
      updateHotspot();
    };

    const Hotspot = React.memo((props) => {
      const onPointerOver = () =>
        controls.current && controls.current.setCursorStyle("pointer");
      const onPointerOut = () =>
        controls.current && controls.current.setCursorStyle("grab");
      const webglHotspots = hotspots.map((hotspot) => {
        hotspot.texture = useLoader(
          THREE.TextureLoader,
          getMediaUrl(hotspot.image_path),
        );
        if (hotspot.active_image_path) {
          hotspot.activeTexture = useLoader(
            THREE.TextureLoader,
            getMediaUrl(hotspot.active_image_path),
          );
        }
        return hotspot;
      });
      const { selectedHotspotId } = props;
      hotspot3Ds = [];
      hotspotHasChildren = {};

      const { gl } = useThree();

      return (
        <group>
          {webglHotspots.map((hotspot, index) => {
            threePosition2(hotspot.position, position);
            let isVisible = true;
            let isSubHotspot = hotspot.parent_id != null;
            if (isSubHotspot) {
              isVisible = hotspot.parent_id == selectedHotspotId;
              hotspotHasChildren[hotspot.parent_id] = true;
            } else {
              isVisible = hotspot.id != selectedHotspotId;
            }

            return (
              /* eslint-disable react/no-unknown-property */
              <sprite
                ref={(r) => {
                  if (r && r.material) {
                    const maxAnisotropy = gl.capabilities.getMaxAnisotropy();

                    r.material.map.minFilter = THREE.LinearMipMapNearestFilter;
                    // r.material.map.minFilter = THREE.LinearMipMapLinearFilter;
                    // r.material.map.minFilter = THREE.NearestMipMapLinearFilter;
                    // r.material.map.minFilter = THREE.LinearFilter;
                    r.material.map.magFilter = THREE.LinearFilter;
                    r.material.precision = "highp";
                    r.material.map.needsUpdate = true;
                    r.material.map.anisotropy = maxAnisotropy;
                    r.material.map.colorSpace = THREE.LinearSRGBColorSpace;
                  }
                  hotspot3Ds.push(r);
                }}
                visible={isVisible}
                layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                onPointerOver={() => onPointerOver()}
                onPointerOut={() => onPointerOut()}
                isHotspotSprite={true}
                userData={hotspot}
                onPointerDown={() => {
                  hotspotPointerDownId = hotspot.id;
                }}
                onPointerUp={(e) => {
                  if (hotspotPointerDownId == hotspot.id) {
                    e.stopPropagation();
                    handleClickHotspot(e?.object.userData);
                  }
                  hotspotPointerDownId = null;
                }}
                key={index}
                position={[position.x, position.y, position.z]}
                scale={[
                  hotspot?.scale?.x || 1,
                  hotspot?.scale?.y || 1,
                  hotspot?.scale?.z || 1,
                ]}
              >
                <spriteMaterial
                  sizeAttenuation={false}
                  fog={false}
                  precision="highp"
                  attach="material"
                  map={hotspot.texture}
                />
              </sprite>
              /* eslint-enable react/no-unknown-property */
            );
          })}
        </group>
      );
    });
    Hotspot.displayName = "Hotspot";

    const RenderInstance = (instance, model) => {
      let isClickable = false;

      let use_color =
        instance.type == OBJECT_TYPES.DO || instance.type == OBJECT_TYPES.FD;
      let use_texture = instance.use_texture;
      let isActive = activeObjectIds.includes(instance.id);

      model.children.map((mesh_, index) => {
        if (mesh_?.material?.color != null && !use_color) {
          let hexString = mesh_.material.color.getHexString();
          Object.assign(instance, { color: `#${hexString}` });
        }
        if (mesh_?.material?.type != "depth-biased-material") {
          mesh_.material = meshDepthBiasedMaterial(mesh_.material);
        }

        const userData = {
          alpha: instance.alpha != null ? instance.alpha / 100.0 : 1.0,
          hover_alpha:
            instance.hover_alpha != null ? instance.hover_alpha / 100.0 : 1,
          active_alpha:
            instance.active_alpha != null ? instance.active_alpha / 100.0 : 1.0,
          color: instance.color ?? "#999999",
          hover_color: instance.hover_color ?? instance.color,
          active_color: instance.active_color ?? instance.color,
          isActive: isActive,
          layer: instance.layer,
          modelGroupName: model.name,
        };

        Object.assign(mesh_, { userData: userData, name: instance.id });
      });

      return model.children.map((mesh_, index) => {
        let isTransparency =
          (instance.alpha != null && instance.alpha <= 80.0) ||
          (instance.hover_alpha != null && instance.hover_alpha <= 80.0) ||
          (instance.active_alpha != null && instance.active_alpha <= 80.0);

        if (isActive) {
          setColor(mesh_.userData.active_color, mesh_);
          isTransparency && setAlpha(mesh_.userData.active_alpha, mesh_);
        } else {
          if (!use_texture || use_color) {
            setColor(mesh_.userData.color, mesh_);
          }
          if (!use_texture || isTransparency) {
            setAlpha(mesh_.userData.alpha, mesh_);
          }
        }

        const onPointerOver =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("pointer");
              instance.hover_color &&
                setColor(mesh_.userData.hover_color, mesh_);
              animateAlpha(mesh_.userData.hover_alpha, mesh_);
              mesh_.userData.isHover = true;
            }
            : null;

        const onPointerOut =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("grab");
              if (mesh_.userData.isHover) {
                setColor(mesh_.userData.color, mesh_);
                animateAlpha(mesh_.userData.alpha, mesh_);
                mesh_.userData.isHover = false;
              }
            }
            : null;

        const onPointerDown = (e) => {
          e.stopPropagation();
          pointerDownId = instance.id;
        };

        const onPointerUp = () => {
          pointerDownId == instance.id && onClick != null && onClick();
          pointerDownId = null;
        };

        const onClick = isClickable
          ? async () => {
            setActiveObjectIds([instance.id]);
            dispatch(reqSetActivePrecinctID(null));

            if (instance.cam_position) {
              const camPosition = threePosition(instance.cam_position);
              const camLookAtPosition =
                instance.cam_focus_point_position != null
                  ? threePosition(instance.cam_focus_point_position)
                  : position;
              handleAreaClick(controls, camLookAtPosition, camPosition);
            }
            if (instance?.modal) {
              dispatch(reqSetIsShowExploreModal(true));
              dispatch(reqSetExploreModal(instance?.modal));
            }
            if (instance?.sub_precinct) {
              dispatch(reqSetActiveTransportOption([instance?.sub_precinct]));
            } else {
              dispatch(reqSetActiveTransportOption([]));
            }
          }
          : null;

        let meshInstance = (
          /* eslint-disable react/no-unknown-property */
          <mesh
            key={index}
            {...mesh_}
            layers={instance.layer != null ? instance.layer : null}
            userData={mesh_.userData}
            name={instance.id}
            visible={false}
            type={"fbx-model-mesh"}
            onPointerDown={instance.type && onPointerDown}
            onPointerUp={instance.type && onPointerUp}
            onPointerOut={instance.type && onPointerOut}
            onPointerOver={instance.type && onPointerOver}
          />
          /* eslint-enable react/no-unknown-property */
        );
        return meshInstance;
      });
    };

    const AnimationCamera = React.memo((props) => {
      const { animation3dSetting, controls } = props;
      const { camera } = useThree();

      new THREE.Vector3(
        -102.89578369966134,
        -1.1178292546754195e-14,
        131.5388245709879,
      );
      const targetPosition =
        animation3dSetting != null && animation3dSetting.cam_position != null
          ? threePosition(animation3dSetting.cam_position)
          : new THREE.Vector3(
            -92.46747002504912,
            260.2837561175679,
            391.6135906913746,
          );
      const delta = new Vector3(
        -200 - targetPosition.x,
        270 - targetPosition.y,
        -630 - targetPosition.z,
      );

      setCameraAnimated(true);

      camera.position.copy(
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z),
      );
      camera.updateProjectionMatrix();

      timeVector3.x = 0;
      timeVector3.y = 0;
      timeVector3.z = 0;

      return <group />;
    });
    AnimationCamera.displayName = "AnimationCamera";

    const CameraControls = React.memo(() => {
      const { camera, gl, raycaster } = useThree();
      const domElement = gl.domElement;

      gl.info.autoReset = false;

      // Set max canvas resolution to 1080p without forcing container style updates
      useThree().gl.setSize(
        Math.min(window.innerWidth, 1280),
        Math.min(window.innerHeight, 720),
        false,
      );

      if (isIntroduction) {
        glS = new glStats(); // init at any point
        tS = new threeStats(gl); // init after WebGLRenderer is created

        rS = new rStats({
          userTimingAPI: true,
          values: {
            frame: { caption: "Total frame time (ms)", over: 16 },
            fps: { caption: "Framerate (FPS)", below: 30 },
            calls: { caption: "Calls (three.js)", over: 3000 },
            raf: { caption: "Time since last rAF (ms)" },
            rstats: { caption: "rStats update (ms)" },
          },
          groups: [
            { caption: "Framerate", values: ["fps", "raf"] },
            {
              caption: "Frame Budget",
              values: ["frame", "texture", "setup", "render"],
            },
          ],
          fractions: [{ base: "frame", steps: ["action1", "render"] }],
          plugins: [tS, glS],
        });

        useFrame(({ gl, scene, camera }) => {
          rS("frame").start();
          glS.start();

          rS("frame").start();
          rS("rAF").tick();
          rS("FPS").frame();

          rS("action1").start();
          rS("action1").end();

          rS("render").start();
          gl.render(scene, camera);
          rS("render").end();

          rS("frame").end();
          rS().update();

          gl.info.reset();
        }, 1);
      }

      useFrame(() => {
        if (controls.current?.needReloadSelectedHotspotId) {
          selectedHotspotId = "";
          updateHotspot();
          controls.current.needReloadSelectedHotspotId = false;
        }

        // if (pointerDown) {
        // }
        if (!isPresentation) {
          sendCameraPos();
        }
        if (!isCameraAnimation && isCameraAnimated) {
          if (controls != null && controls.current != null && !isPresentation) {
            controls.current.update();
          }
          return;
        }
      }, 2);

      let x = targetPosition.x;
      let y = targetPosition.y;
      let z = targetPosition.z;

      if (camera.targetPosition) {
        x = camera.targetPosition.x;
        y = camera.targetPosition.y;
        z = camera.targetPosition.z;
      }

      return (
        <orbitCustomControls
          ref={controls}
          args={[camera, domElement, [0, 0, 0], [0, 0, 0]]}
          raycaster={raycaster}
          disabledUpdate={false}
          neverUpdate={false}
          autoRotate={false}
          enableDamping={true}
          maxDistance={1000}
          minDistance={2}
          zoomSpeed={2}
          rotateSpeed={0.8}
          minZoom={_3dSetting.minZoom ?? 0.2}
          maxZoom={_3dSetting.maxZoom ?? 8}
          minHeight={10}
          maxHeight={1000}
          movingCurveSpeed={1 ?? 0.5}
        />
      );
    });
    CameraControls.displayName = "CameraControls";

    function FbxModel() {
      if (!isIntroduction) {
        return <group />;
      }
      if (light.current != null) {
        light.current.layers.enableAll();
      }
      return (
        <group ref={refScene} name="FbxModelGroup">
          {Object.keys(meshInstanceMap).map((entry) => {
            const targetMap = meshInstanceMap[entry];
            if (!targetMap) return;
            const model = targetMap.model;
            const instances = targetMap.instances;
            return instances.map((instance) => {
              return RenderInstance(instance, model);
            });
          })}
        </group>
      );
    }

    return (
      /* eslint-disable react/no-unknown-property */
      <>
        <Canvas
          gl={
            {
              // logarithmicDepthBuffer: true,
              // outputEncoding: THREE.sRGBEncoding,
            }
          }
          // pixelRatio={Math.max(window.devicePixelRatio, 2)}
          // pixelRatio={Math.min(window.devicePixelRatio, 1.75)}
          // pixelRatio={2}
          camera={{
            position: [
              1020 + _3dSetting.cam_position.x,
              540 + _3dSetting.cam_position.z,
              630 - _3dSetting.cam_position.y,
            ],
            fov: _3dSetting.FOV,
            near: 10,
            far: 20000,
          }}
          onPointerEnter={(e) => (pointerDown = true)}
          onPointerLeave={(e) => (pointerDown = false)}
          onTouchStart={(e) => (pointerDown = true)}
          onTouchEnd={(e) => (pointerDown = false)}
        // onWheel={(e) => {
        //   sendCameraPos();
        // }}
        >
          <App />
          {isIntroduction && !isCameraAnimated && (
            <AnimationCamera
              animation3dSetting={_3dSetting}
              controls={controls}
            />
          )}

          {/* <ambientLight intensity={0.2} color={0x2e2e2a} />
          {
            <hemisphereLight
              intensity={0.4}
              skyColor={0xb1e1ff}
              groundColor={0x2e2e2a}
              position={[0, -10, 0]}
            />
          }
          <directionalLight
            ref={light}
            intensity={1.6}
            color={0xffffff}
            position={[-1500, 600, 250]}
          />
          {
            <directionalLight
              intensity={0.7}
              color={0xffffff}
              position={[1500, 600, -250]}
            />
          } */}

          <React.Suspense fallback={null}>
            <FbxModel />
            <Hotspot selectedHotspotId={selectedHotspotId} />
          </React.Suspense>
          {<CameraControls />}
        </Canvas>
        <FPSStats />
      </>
      /* eslint-enable react/no-unknown-property */
    );
  }),
);

CanvasBox.displayName = "CanvasBox";

export default CanvasBox;
